var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"elevation-0",attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.reminders,"loading":_vm.loading,"sort-by":"type","search":_vm.search},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("Reminders")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('div',{staticClass:"flex-grow-1"}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{staticClass:"flex-grow-1"})],1)]},proxy:true},{key:"item.expiryDate",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.expiryDate(item)))]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.reminder(item)))]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.silence(item)}}},on),[_c('v-icon',[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Silence Reminder")])])]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }