<!-- eslint-disable vue/valid-v-slot -->
<template>
  <Layout>
    <v-row>
      <v-col cols="12">
        <v-card class="elevation-0" outlined>
          <v-data-table
            :headers="headers"
            :items="reminders"
            :loading="loading"
            sort-by="type"
            class="elevation-0"
            :search="search"
          >
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-toolbar-title>Reminders</v-toolbar-title>
                <v-divider class="mx-4" inset vertical></v-divider>
                <div class="flex-grow-1"></div>
                <v-text-field
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <div class="flex-grow-1"></div>
              </v-toolbar>
            </template>
            <template v-slot:item.expiryDate="{ item }">{{
              expiryDate(item)
            }}</template>
            <template v-slot:item.type="{ item }">{{
              reminder(item)
            }}</template>
            <template v-slot:item.action="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="silence(item)">
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-btn>
                </template>
                <span>Silence Reminder</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </Layout>
</template>
<script>
import Layout from '@layouts/main'
import api from '@src/api/index.js'
import EventBus from '@utils/EventBus'
import { format } from 'date-fns'
import { documentTypes, EventBusEvents } from '@src/constants/index.js'
export default {
  name: 'Reminders',
  components: { Layout },
  data: () => ({
    reminders: [],
    headers: [
      {
        text: 'Client',
        align: 'left',
        sortable: false,
        value: 'client',
      },
      {
        text: 'Reminder',
        value: 'type',
        align: 'center',
      },
      {
        text: 'Due Date',
        value: 'expiryDate',
        align: 'center',
      },
      {
        text: 'Actions',
        value: 'action',
        sortable: false,
        align: 'center',
      },
    ],
    mType: 'new',
    mOpen: false,
    mItem: null,
    search: '',
    editedItem: [{}],
    editedIndex: -1,
  }),
  created() {
    this.getAllReminders()
  },

  methods: {
    closeModal() {
      this.mOpen = false
    },
    expiryDate({ expiryDate }) {
      return format(new Date(expiryDate), 'dd MMM yyyy')
    },
    async getAllReminders() {
      this.loading = true
      try {
        const reminders = await api.getAllReminders()
        this.reminders = reminders
        this.loading = false
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    silence(item) {
      EventBus.$emit(EventBusEvents.OPEN_MODAL, {
        title: 'Silence reminder',
        message: 'Are you sure to silence the reminder?',
        confirmAction: () => this.silenceReminder(item),
      })
    },
    async silenceReminder(item) {
      const { accountingId, documentId, riskId, type } = item
      const payload = {
        ...(documentId && { documentId }),
        ...(accountingId && { accountingId }),
        ...(riskId && { riskId }),
        ...(accountingId && type && { accountingType: type }),
      }

      this.loading = true
      try {
        await api.silenceReminder(payload)
        this.loading = false
        this.getAllReminders()
      } catch (err) {
        this.loading = false
        EventBus.$emit(EventBusEvents.SNACKBAR_ERROR, err, this)
      }
    },
    reminder({ type }) {
      if (Object.keys(documentTypes).includes(type)) {
        return `Document expiring: ${documentTypes[type]}`
      }

      const otherExpirings = {
        fsStatement: 'Fs Statement',
        vatReturn: 'VAT Return',
        incomeTaxReturn: 'Income Tax Return',
      }

      return Object.keys(otherExpirings).includes(type)
        ? `Period expiring: ${otherExpirings[type]}`
        : 'Risk: next review'
    },
  },
}
</script>
